<template>
  <div>
    <div class="uk-container uk-margin-medium-top" v-if="!loading">
      <h2>
        {{ topic.title }} {{ topic.status == "closed" ? "(CLOSED)" : "" }}
      </h2>
      <p>
        Date: <strong>{{ $localizer.parse(topic.date) }}</strong>
      </p>
      <div class="uk-grid">
        <div class="uk-width-1-3@m">
          <div>
            <div class="uk-card uk-card-default uk-card-body">
              <div v-html="topic.content"></div>
            </div>
          </div>
        </div>
        <div class="uk-width-2-3@m">
          <div v-if="topic.attachment == 'image'" class="mb-4 text-center">
            <a :href="topic.image_url" target="_blank"><img :src="topic.image_url" :alt="topic.title" /></a>
          </div>
          <div v-if="topic.attachment == 'video'" class="mb-4">
            <vimeo-player
              v-if="! topic.mega"
              class="vimeo-player"
              ref="player"
              :video-url="topic.video_url"
              player-width="auto"
              player-height="auto"
              :options="{ responsive: true }"
            />

            <mega-player v-if="topic.mega" :src="topic.video_url" />
          </div>
          <hr />
          <chat-box
            type="discussions"
            role="trader"
            :id="$route.params.id"
            :title="topic.title"
            @updated="updateComments"
            :open="topic.status == 'open'"
          />
        </div>
      </div>
    </div>

    <template v-if="loading">
      <div class="col-md-9">
        <div class="text-center">
          <div><img src="/logo.png" alt="logo" style="margin-top: 5rem" /></div>
          <div class="uk-spinner"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  components: {},

  mounted() {
    this.fetch();
  },

  data() {
    return {
      topic: { author: {} },
      current_comments: 0,
      users: [],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.$axios
        .get(`/api/v1/trader/discussions/${this.$route.params.id}`)
        .then((response) => {
          this.topic = response.data.topic;
          this.users = response.data.users;
          this.loading = false;
        });
    },

    updateComments(total) {
      this.current_comments = total;
    },
  },
};
</script>
